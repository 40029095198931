export class HttpError extends Error {
  constructor(
    public name: string,
    public status: number,
    public url: string,
    public duration: number,
    public correlationId?: string,
    public response?: unknown,
  ) {
    super();
  }
}
