import { type FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, type ModalProps } from '@/components/shared/Modal';
import { acknowledgeAuctionRequested } from '@/store/auctions';
import { closeAcknowledgeModal, getAcknowledgeAuctionUuid, getAcknowledgeIsLoading } from '@/store/ui';
import { useDispatch, useSelector } from 'react-redux';

export const AcknowledgeModal: FC<ModalProps> = () => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(closeAcknowledgeModal());
  }, [dispatch]);

  const auctionUuid = useSelector(getAcknowledgeAuctionUuid());
  const isLoading = useSelector(getAcknowledgeIsLoading());

  const onConfirm = useCallback(() => {
    dispatch(acknowledgeAuctionRequested(auctionUuid));
  }, [dispatch, auctionUuid]);

  return (
    <Modal
      title="Dealer status update"
      initialDisplayed={true}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      bordered
      color="primary"
      children={
        <>
          <p>
            <FormattedMessage id="acknowledge.confirmation" />
          </p>
        </>
      }
      loading={isLoading}
    />
  );
};
