import { Modal } from '@/components/shared/Modal';
import { closePdcDetailsModal, getPdcModalRfqId } from '@/store/ui';
import { getPdcDetails } from '@/store/auctions';
import { pdcTypes } from '@/helper/pdc';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PdcDerogationCard from '@/components/Modal/Pdc/PdcDerogationCard';
import PdcDetailsRow from '@/components/Modal/Pdc/PdcDetailsRow';
import PdcTableSkeleton from '@/components/Modal/Pdc/PdcTableSkeleton';
import type { ModalProps } from '@/components/shared/Modal';
import type { OptionItem } from '@/models';
import type { PdcType } from '@/helper/pdc';

interface PdcDetailsModalProps extends ModalProps {
  initialDisplayed?: boolean;
}

const PdcDetailsModal: React.FC<PdcDetailsModalProps> = ({ initialDisplayed }) => {
  const dispatch = useDispatch();

  const rfqId = useSelector(getPdcModalRfqId());
  const pdcDetails = useSelector(getPdcDetails(rfqId));

  const [derogationReasonOptionItems, setDerogationReasonOptionItems] = useState<OptionItem[]>([]);
  const [fullPdcDetailsUrl, setFullPdcDetailsUrl] = useState<string | undefined>(undefined);
  const [strategyIds, setStrategyIds] = useState<(string | undefined)[]>([]);
  const [existingPdcTypes, setExistingPdcTypes] = useState<PdcType[]>(pdcTypes);

  useEffect(() => {
    setStrategyIds(
      (
        pdcDetails?.transaction?.pdcs
          ?.map(pdc => pdc.strategyUuid)
          .filter((value, index, self) => self.indexOf(value) === index) ?? []
      ).filter(id => id !== undefined && id !== ''),
    );
    setFullPdcDetailsUrl(pdcDetails?.transaction?.pdcs?.find(pdc => pdc.type === 'CREDIT_CHECK_ASK')?.url);
    const allExistingPdcTypes = pdcDetails?.transaction?.pdcs
      ?.filter(pdc => pdc.level === 'ALLOCATION')
      ?.map(pdc => pdc.type);
    setExistingPdcTypes(pdcTypes.filter(type => allExistingPdcTypes?.includes(type.id)));
  }, [pdcDetails]);

  const onConfirm = useCallback(() => {
    if (fullPdcDetailsUrl !== undefined) {
      window.open(fullPdcDetailsUrl);
    }
  }, [fullPdcDetailsUrl]);

  const borderRightStyle = '1px lightGrey solid';

  return (
    <Modal
      initialDisplayed={initialDisplayed}
      id="pdc-details-modal"
      title={`Pre-Deal check (PDC) status`}
      minWidth={800}
      color={'primary'}
      children={
        <>
          {pdcDetails === undefined ? (
            <div data-e2e="loading-skeleton" className={`w-100 flex-fill mb-4`}>
              <PdcTableSkeleton borderRightStyle={borderRightStyle} />
            </div>
          ) : (
            <table className="w-100 table-sm">
              <thead>
                <tr className="border-bottom">
                  <th></th>
                  <th className="fw-semibold text-center" style={{ maxWidth: '150px', borderRight: borderRightStyle }}>
                    {pdcDetails?.transaction?.rfq?.salesCounterparty?.name}
                  </th>
                  {strategyIds.map((strategyId, strategyIndex) => {
                    const legIds = (
                      pdcDetails?.transaction?.pdcs
                        ?.filter(pdc => pdc.strategyUuid === strategyId)
                        ?.map(pdc => pdc.legUuid)
                        .filter((value, index, self) => self.indexOf(value) === index) ?? []
                    ).filter(id => id !== undefined && id !== '');
                    return (
                      <>
                        <th className="fw-semibold">
                          <div className="d-flex justify-content-center">{`Strategy ${strategyIndex + 1}`}</div>
                        </th>
                        {legIds.map((legId, legIndex) => (
                          <th
                            key={legId}
                            className="fw-semibold"
                            style={{
                              borderRight:
                                legIndex === legIds.length - 1 && strategyIndex !== strategyIds.length - 1
                                  ? borderRightStyle
                                  : '',
                            }}
                          >
                            <div className="d-flex justify-content-center">{`Leg ${legIndex + 1}`}</div>
                          </th>
                        ))}
                      </>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {existingPdcTypes.map((type, typeIndex) => (
                  <PdcDetailsRow
                    key={type.id}
                    type={type}
                    typeIndex={typeIndex}
                    existingPdcTypes={existingPdcTypes}
                    borderRightStyle={borderRightStyle}
                    rfqId={rfqId}
                    strategyIds={strategyIds}
                    setDerogationReasonOptionItems={setDerogationReasonOptionItems}
                  />
                ))}
              </tbody>
            </table>
          )}
        </>
      }
      bottomCard={<PdcDerogationCard rfqId={rfqId} derogationReasonOptionItems={derogationReasonOptionItems} />}
      onClose={() => dispatch(closePdcDetailsModal())}
      onCancel={() => dispatch(closePdcDetailsModal())}
      cancelActionLabelKey="Dismiss"
      confirmActionHidden={fullPdcDetailsUrl === undefined}
      confirmActionLabelKey={'See full PDC status'}
      onConfirm={() => onConfirm()}
      confirmationPurpose="default"
      confirmationIcon="open_in_new"
      loading={false}
    />
  );
};

export default PdcDetailsModal;
