import { type FC, useCallback, useEffect, useRef } from 'react';
import { FormattedText } from '@/components/shared/FormattedText';
import classNames from 'classnames';
import type { ChangeEvent } from 'react';
import type { IntlKey } from '@/locales';

export interface CheckboxProps {
  id?: string;
  disabled?: boolean;
  onChange?: (checked: boolean, id: string) => void;
  className?: string;
  checked?: boolean;
  label?: IntlKey;
  isIntermediate?: boolean;
}

export const Checkbox: FC<CheckboxProps> = ({ id, disabled, onChange, className, checked, label, isIntermediate }) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked, id } = e.target;
      if (!disabled) {
        onChange?.(checked, id);
      }
    },
    [disabled, onChange],
  );

  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!input || !input.current) {
      return;
    }

    if (isIntermediate) {
      input.current.indeterminate = true;
    } else {
      input.current.indeterminate = false;
    }
  }, [input, isIntermediate]);

  return (
    <div className={classNames('form-check mb-0', className)}>
      <input
        type="checkbox"
        className="form-check-input"
        id={id}
        onChange={handleOnChange}
        checked={checked}
        ref={input}
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {label && <FormattedText id={label} />}
      </label>
    </div>
  );
};
