import { enrichStrategyFormCleared } from '@/store/auctions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const useGoCurrentPage = (): (() => void) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return useCallback(() => {
    dispatch(enrichStrategyFormCleared());
    navigate('/auctions/current');
  }, [dispatch, navigate]);
};
