import Skeleton from 'react-loading-skeleton';

interface PdcTableSkeletonProps {
  borderRightStyle?: string;
}

const PdcTableSkeleton: React.FC<PdcTableSkeletonProps> = ({ borderRightStyle }) => {
  return (
    <>
      <table className="w-100 table-sm">
        <tbody>
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
          <PdcRowSkeleton borderRightStyle={borderRightStyle} />
        </tbody>
      </table>
    </>
  );
};

interface PdcRowSkeletonProps {
  borderRightStyle?: string;
}

const PdcRowSkeleton: React.FC<PdcRowSkeletonProps> = ({ borderRightStyle }) => {
  return (
    <tr>
      <td className="fw-semibold text-center" style={{ borderRight: borderRightStyle }}>
        <Skeleton className="w-100" />
      </td>
      <td className="fw-semibold text-center" style={{ borderRight: borderRightStyle }}>
        <Skeleton className="w-100" />
      </td>
      <td className="fw-semibold text-center" style={{ borderRight: borderRightStyle }}>
        <Skeleton className="w-100" />
      </td>
      <td className="fw-semibold text-center">
        <Skeleton className="w-100" />
      </td>
    </tr>
  );
};

export default PdcTableSkeleton;
