import type { DealerConfirmationStatus, PdcStatus, RfqStatus } from '@/models';

export function getPdcStatusTextColor(pdcStatus?: PdcStatus) {
  switch (pdcStatus) {
    case 'NEW':
    case 'COMPUTING':
    case 'UNDEFINED':
      return 'text-info';
    case 'OK':
      return 'text-success fw-semibold';
    case 'DEROGATED':
      return 'text-warning fw-semibold';
    case 'KO':
      return 'text-danger fw-semibold';
    case 'WARNING':
      return 'text-warning';
    case 'ERROR':
      return 'text-danger';
    default:
      return 'text-info';
  }
}

export function getRfqStatusTextColor(rfqStatus?: RfqStatus) {
  return rfqStatus === 'NEW' ? 'text-info' : 'text-primary';
}

export function getExecutionStatusTextColor(executionStatus?: DealerConfirmationStatus) {
  switch (executionStatus) {
    case 'PENDING':
      return 'text-info';
    case 'MISSED':
      return 'text-secondary';
    case 'ACKNOWLEDGED':
      return 'text-success';
    default:
      return '';
  }
}
