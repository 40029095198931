import { toast } from '@/components/shared/Toast';
import type { IntlKey } from '@/locales';

export type SuccessIntlKey = IntlKey & `copyToClipboard.success`;
export type ErrorIntlKey = IntlKey & `copyToClipboard.error`;

export type CopyToClipboardHandler = {
  validate: () => boolean;
  validateClipboardItem: () => boolean;
  onSuccess: () => void;
  onError: (err: any) => void;
};

export function triggerCopyToClipboard(handler: CopyToClipboardHandler, text?: string, showToast = true): void {
  if (!handler.validate()) {
    return;
  }
  navigator.clipboard
    .writeText(text ?? '')
    .then(showToast ? handler.onSuccess : undefined)
    .catch(showToast ? handler.onError : undefined);
}

export function copyToClipboardHandler(
  successMessage: SuccessIntlKey = 'copyToClipboard.success',
  errorMessage: ErrorIntlKey = 'copyToClipboard.error',
): CopyToClipboardHandler {
  return getCopyToClipboardHandler(successMessage, errorMessage);
}

function getCopyToClipboardHandler(successMessage: SuccessIntlKey, errorMessage: ErrorIntlKey) {
  const headerIntlKey = 'toast.copyToClipboard.header';
  return {
    validate: () => {
      if (!navigator.clipboard) {
        toast.danger({
          header: { id: headerIntlKey },
          message: { id: errorMessage },
        });
        return false;
      }
      return true;
    },
    validateClipboardItem: () => {
      if (!window.ClipboardItem) {
        toast.danger({
          header: { id: headerIntlKey },
          message: { id: errorMessage },
        });
        return false;
      }
      return true;
    },
    onSuccess: () =>
      toast.success({
        header: { id: headerIntlKey },
        message: { id: successMessage },
      }),
    onError: () => {
      toast.danger({
        header: { id: headerIntlKey },
        message: { id: errorMessage },
      });
    },
  };
}
