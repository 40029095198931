export class HttpTimeoutError extends Error {
  public name = 'HttpTimeoutError';

  constructor(
    public timeout: number,
    public correlationId: string = '',
  ) {
    super();
  }
}
