import { type FC, useCallback } from 'react';
import { FormattedText } from '@/components/shared/FormattedText';
import { RadioButton } from '@/components/shared/RadioButton';
import { Td } from '@/components/shared/Table';
import { executeAuctionsDealerSelected } from '@/store/auctions';
import { formatCcyValue } from '@/utils/string';
import { formatTimeWithTimeZoneAbbreviation } from '@/utils/date';
import { isDefined, isEmpty, isNonEmpty } from '@sgme/fp';
import { useDispatch } from 'react-redux';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import classNames from 'classnames';
import styled from 'styled-components';
import type { AuctionBaseRfq, RfqDealers } from '@/models';

interface DealerPriceCellProps {
  className?: string;
  dealer: RfqDealers;
  auctionBaseRfq?: AuctionBaseRfq;
  shouldSelectAuctionWinner?: boolean;
  showPriceAndUnitOnly?: boolean;
  displayInCell?: boolean;
  isConfirmed?: boolean;
  isPending?: boolean;
}

export const DealerPriceCell: FC<DealerPriceCellProps> = ({
  shouldSelectAuctionWinner,
  dealer,
  auctionBaseRfq,
  showPriceAndUnitOnly,
  displayInCell,
  isConfirmed,
  isPending,
}) => {
  const { sendForBidding, price, receiveTime, auctionId, dealerRfqId } = dealer;
  if (!sendForBidding) {
    return <FormattedText as={Td} align="right" />;
  }

  if (!isDefined(price)) {
    if (!displayInCell) {
      return (
        <div className="text-info">
          <FormattedText id={`rfqStatus.Pending`} />
        </div>
      );
    }
    return <FormattedText as={Td} align={'right'} id={`rfqStatus.Pending`} className="text-info" />;
  }

  const { value, unit } = price;
  const timeZoneId = auctionBaseRfq?.salesDiffusion?.salesValo?.timeZoneId;
  return (
    <PricedStatusCell
      auctionId={auctionId}
      dealerRfqId={dealerRfqId}
      value={value}
      unit={unit}
      quoteTime={receiveTime}
      timeZoneId={timeZoneId}
      shouldSelectAuctionWinner={shouldSelectAuctionWinner}
      showPriceAndUnitOnly={showPriceAndUnitOnly}
      displayInCell={displayInCell}
      isConfirmed={isConfirmed}
      isPending={isPending}
    />
  );
};

const StyledTd = styled.td<{ isConfirmed: boolean }>`
  background-color: ${props => (props.isConfirmed ? 'rgba(0, 170, 12, 0.1)' : 'rgba(36,105,255,.1)')} !important;
`;

interface PricedStatusCellProps {
  auctionId?: string;
  dealerRfqId?: string;
  className?: string;
  value?: number;
  unit?: string;
  quoteTime?: string;
  timeZoneId?: string;
  shouldSelectAuctionWinner?: boolean;
  showPriceAndUnitOnly?: boolean;
  displayInCell?: boolean;
  isConfirmed?: boolean;
  isPending?: boolean;
}
const PricedStatusCell: FC<PricedStatusCellProps> = ({
  auctionId,
  dealerRfqId,
  value,
  unit,
  quoteTime,
  timeZoneId,
  shouldSelectAuctionWinner,
  className,
  showPriceAndUnitOnly,
  displayInCell,
  isConfirmed = false,
  isPending = false,
}) => {
  const isDisplayExecutionDetailsEnabled = useToggleFeature('DisplayExecutionDetails');
  const isDealerExecutionConfirmation = useToggleFeature('DealerExecutionConfirmation');
  if (displayInCell) {
    const isHighlighted = isDealerExecutionConfirmation ? isConfirmed || isPending : isConfirmed;
    if (isHighlighted && isDisplayExecutionDetailsEnabled) {
      return (
        <StyledTd isConfirmed={isDealerExecutionConfirmation ? isConfirmed : true} className={classNames(className)}>
          <PricedStatus
            auctionId={auctionId}
            dealerRfqId={dealerRfqId}
            value={value}
            unit={unit}
            quoteTime={quoteTime}
            timeZoneId={timeZoneId}
            shouldSelectAuctionWinner={shouldSelectAuctionWinner}
            showPriceAndUnitOnly={showPriceAndUnitOnly}
          />
        </StyledTd>
      );
    }
    return (
      <Td className={classNames(className)}>
        <PricedStatus
          auctionId={auctionId}
          dealerRfqId={dealerRfqId}
          value={value}
          unit={unit}
          quoteTime={quoteTime}
          timeZoneId={timeZoneId}
          shouldSelectAuctionWinner={shouldSelectAuctionWinner}
          showPriceAndUnitOnly={showPriceAndUnitOnly}
        />
      </Td>
    );
  }

  return (
    <PricedStatus
      auctionId={auctionId}
      dealerRfqId={dealerRfqId}
      value={value}
      unit={unit}
      quoteTime={quoteTime}
      timeZoneId={timeZoneId}
      shouldSelectAuctionWinner={shouldSelectAuctionWinner}
      showPriceAndUnitOnly={showPriceAndUnitOnly}
    />
  );
};

interface PricedStatusProps {
  auctionId?: string;
  dealerRfqId?: string;
  className?: string;
  value?: number;
  unit?: string;
  quoteTime?: string;
  timeZoneId?: string;
  shouldSelectAuctionWinner?: boolean;
  showPriceAndUnitOnly?: boolean;
}
const PricedStatus: FC<PricedStatusProps> = ({
  auctionId,
  dealerRfqId,
  value,
  unit,
  quoteTime,
  timeZoneId,
  shouldSelectAuctionWinner,
  showPriceAndUnitOnly,
}) => {
  const dispatch = useDispatch();

  const onDealerSelected = useCallback(
    (dealerId: string) => {
      if (isEmpty(auctionId) || isEmpty(dealerId)) {
        return;
      }
      dispatch(executeAuctionsDealerSelected(auctionId, dealerId));
    },
    [auctionId, dispatch],
  );
  const className = showPriceAndUnitOnly ? '' : 'ms-auto';
  return (
    <>
      <div className="d-flex justify-content-between">
        {!showPriceAndUnitOnly && (
          <>
            {shouldSelectAuctionWinner && (
              <RadioButton
                id={dealerRfqId}
                size="sm"
                className="me-2"
                groupName={`${auctionId}-dealer-price`}
                onChange={onDealerSelected}
                data-e2e={`${auctionId}-dealer-price`}
              />
            )}
            {isNonEmpty(quoteTime) && isNonEmpty(timeZoneId) && (
              <span className="text-secondary">{`Quoted at ${formatTimeWithTimeZoneAbbreviation(quoteTime, timeZoneId)}`}</span>
            )}
          </>
        )}
        <div className={classNames(className)}>
          <span className="fw-semibold">{isDefined(value) ? formatCcyValue(value) : ''}</span>
          <span className="ms-2 text-secondary">{unit}</span>
        </div>
      </div>
    </>
  );
};
