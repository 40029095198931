import {
  AUCTION_DEALERS_RECEIVED,
  CANCEL_AUCTION_RECEIVED,
  CREATE_AUCTION_FAILED,
  CREATE_AUCTION_QUEUED,
  CREATE_AUCTION_RECEIVED,
  CREATE_AUCTION_REQUESTED,
  CURRENT_AUCTIONS_FAILED,
  CURRENT_AUCTIONS_RECEIVED,
  CURRENT_AUCTIONS_REQUESTED,
  DEROGATE_RECEIVED,
  DEROGATION_REASONS_RECEIVED,
  EDIT_AUCTION_FAILED,
  EDIT_AUCTION_QUEUED,
  EDIT_AUCTION_RECEIVED,
  EDIT_AUCTION_REQUESTED,
  ENRICH_STRATEGY_FORM_CLEARED,
  ENRICH_STRATEGY_FORM_FAILED,
  ENRICH_STRATEGY_FORM_RECEIVED,
  ENRICH_STRATEGY_FORM_REQUESTED,
  EXECUTE_AUCTIONS_BOOKING_DETAILS_PATCH_REQUESTED,
  EXECUTE_AUCTIONS_CONFIRMATION_CANCELLED,
  EXECUTE_AUCTIONS_CONFIRMATION_INITIATED,
  EXECUTE_AUCTIONS_CONFIRMATION_STEP_CHANGED,
  EXECUTE_AUCTIONS_DEALER_SELECTED,
  EXECUTE_AUCTIONS_FAILED,
  EXECUTE_AUCTIONS_RECEIVED,
  EXECUTE_AUCTIONS_REQUESTED,
  INIT_STRATEGY_FORM_RECEIVED,
  INIT_STRATEGY_FORM_REQUESTED,
  LATEST_BLOTTER_ITEMS_RECEIVED,
  OVERRIDE_QUOTE_RECEIVED,
  PDC_DETAILS_FAILED,
  PDC_DETAILS_RECEIVED,
  RELOAD_AUCTION_RECEIVED,
  RELOAD_AUCTION_REQUESTED,
} from './auctions.actions';
import { UI_AUCTION_START_TIME_RANGE_CHANGED, UI_EDIT_DETAILS_CLICKED, UI_NEW_AUCTION_CLICKED } from '@/store/ui';
import { initialAuctionsState } from '@/store/auctions/auctions.model';
import { patchObject } from '@/utils/object';
import type { Actions } from '@/store/app.actions';
import type { AuctionExecutionState } from '@/models/AuctionExecutionState';
import type { AuctionsState } from '@/store/auctions/auctions.model';
import type { BlotterItem, StrategyFormResult, VariationParameters } from '@/models';
import type { components } from '@/models/WebAPI.generated';

export function auctionsReducer(state: AuctionsState = initialAuctionsState, action: Actions): AuctionsState {
  switch (action.type) {
    // TODO: Migrate UI_AUCTION_START_TIME_RANGE_CHANGED to use only CURRENT_AUCTIONS_REQUESTED
    case CURRENT_AUCTIONS_REQUESTED:
    case UI_AUCTION_START_TIME_RANGE_CHANGED:
      return patchObject(state, 'current', { isLoading: true });

    case CURRENT_AUCTIONS_RECEIVED:
    case LATEST_BLOTTER_ITEMS_RECEIVED:
      return patchObject(state, 'current', { ...state.current, isLoading: false, blotterItems: action.blotterItems });

    case CURRENT_AUCTIONS_FAILED:
      return patchObject(state, 'current', { isLoading: false, error: action.error });

    case UI_NEW_AUCTION_CLICKED:
      return patchObject(state, 'create', { isLoading: false });

    case CREATE_AUCTION_REQUESTED:
      return patchObject(state, 'create', { ...state.create, isLoading: true, error: undefined });

    case CREATE_AUCTION_RECEIVED:
      return patchObject(state, 'create', { isLoading: false, saveAfterEnrich: false, response: action.response });

    case CREATE_AUCTION_FAILED:
      return patchObject(state, 'create', { isLoading: false, saveAfterEnrich: false, error: action.error });

    case CREATE_AUCTION_QUEUED:
      return patchObject(state, 'create', { isLoading: false, saveAfterEnrich: true });

    case UI_EDIT_DETAILS_CLICKED:
      return patchObject(state, 'edit', { isLoading: false });

    case EDIT_AUCTION_REQUESTED:
      return patchObject(state, 'edit', { isLoading: true, error: undefined });

    case EDIT_AUCTION_RECEIVED:
      return patchObject(state, 'edit', {
        isLoading: false,
        saveAfterEnrich: false,
        response: action.strategyFormResult,
      });

    case EDIT_AUCTION_FAILED:
      return patchObject(state, 'edit', { isLoading: false, saveAfterEnrich: false, error: action.error });

    case EDIT_AUCTION_QUEUED:
      return patchObject(state, 'edit', { isLoading: false, saveAfterEnrich: true, auctionUuid: action.auctionUuid });

    case INIT_STRATEGY_FORM_REQUESTED:
    case RELOAD_AUCTION_REQUESTED:
      return patchObject(state, 'enrich', { ...state.enrich, isInit: true });

    case INIT_STRATEGY_FORM_RECEIVED:
    case RELOAD_AUCTION_RECEIVED:
      return patchObject(state, 'enrich', {
        ...state.enrich,
        isInit: false,
        strategyFormResult: action.strategyFormResult,
        pendingEnrichment: action.strategyFormResult,
      });
    case ENRICH_STRATEGY_FORM_RECEIVED:
      return patchObject(state, 'enrich', {
        ...state.enrich,
        isLoading: false,
        strategyFormResult: action.strategyFormResult,
        pendingEnrichment: action.strategyFormResult,
      });

    case ENRICH_STRATEGY_FORM_FAILED:
      return patchObject(state, 'enrich', {
        ...state.enrich,
        isLoading: false,
        error: action.error,
        strategyFormResult: state.enrich.strategyFormResult,
        pendingEnrichment: state.enrich.strategyFormResult,
      });

    case ENRICH_STRATEGY_FORM_REQUESTED:
      return patchObject(state, 'enrich', {
        ...state.enrich,
        isLoading: true,
        pendingEnrichment: updateStrategyFormResult(action.variationParameters, state.enrich.pendingEnrichment),
      });

    case ENRICH_STRATEGY_FORM_CLEARED:
      return patchObject(state, 'enrich', {
        ...state.enrich,
        pendingEnrichment: undefined,
        strategyFormResult: undefined,
      });

    case AUCTION_DEALERS_RECEIVED: {
      return updateStateWhenReceivedDealers(state, action.payload, action.auctionUuid);
    }

    case EXECUTE_AUCTIONS_DEALER_SELECTED: {
      const auctionState: AuctionExecutionState = state.execute.auctions?.[action.auctionId] ?? {
        id: action.auctionId,
      };
      const updatedAuctionState = { ...auctionState, dealerId: action.dealerId } satisfies AuctionExecutionState;

      return patchObject(state, 'execute', {
        ...state.execute,
        auctions: {
          ...state.execute.auctions,
          [action.auctionId]: updatedAuctionState,
        },
      });
    }

    case EXECUTE_AUCTIONS_CONFIRMATION_INITIATED: {
      const firstInstrument = action.auction?.auctionBaseRfq?.instruments?.[0];
      return patchObject(state, 'execute', {
        ...state.execute,
        confirmation: {
          auctionId: action.auctionId,
          dealerId: action.dealerId,
          step: 'EDITION',
          bookingDetails: {
            bookingCenter: firstInstrument?.bookingCenter,
            bookingPortfolio: firstInstrument?.bookingPortfolio,
            markitWireId: action.auction?.markitWireId,
          },
        },
        error: undefined,
      });
    }
    case EXECUTE_AUCTIONS_CONFIRMATION_CANCELLED: {
      return patchObject(state, 'execute', {
        ...state.execute,
        confirmation: undefined,
        error: undefined,
      });
    }
    case EXECUTE_AUCTIONS_CONFIRMATION_STEP_CHANGED: {
      return patchObject(state, 'execute', {
        ...state.execute,
        confirmation: {
          ...state.execute.confirmation,
          step: action.step,
        },
      });
    }
    case EXECUTE_AUCTIONS_BOOKING_DETAILS_PATCH_REQUESTED: {
      return patchObject(state, 'execute', {
        ...state.execute,
        confirmation: {
          ...state.execute.confirmation,
          step: 'EDITION',
          bookingDetails: { ...state.execute.confirmation?.bookingDetails, ...action.bookingDetails },
        },
      });
    }
    case EXECUTE_AUCTIONS_REQUESTED: {
      return patchObject(state, 'execute', {
        ...state.execute,
        isLoading: true,
      });
    }
    case EXECUTE_AUCTIONS_RECEIVED: {
      return patchObject(state, 'execute', {
        ...state.execute,
        confirmation: undefined,
        isLoading: false,
      });
    }
    case EXECUTE_AUCTIONS_FAILED: {
      return patchObject(state, 'execute', {
        ...state.execute,
        isLoading: false,
        error: action.error,
      });
    }
    case OVERRIDE_QUOTE_RECEIVED: {
      const blotterItem: BlotterItem | undefined = state.current.blotterItems?.find(
        a => a.auction.auctionUuid === action.auctionUuid,
      );
      if (blotterItem === undefined) {
        return state;
      }
      const dealer = blotterItem.auction.rfqDealers?.find(d => d.dealerRfqId === action.dealerRfqId);
      if (dealer === undefined) {
        return state;
      }
      dealer.receiveTime = action.receiveTime;
      dealer.price = {
        value: action.priceValue ?? undefined,
        unit: action.priceUnit ?? undefined,
        type: action.priceType ?? undefined,
      };
      return patchObject(state, 'current', {
        ...state.current,
        blotterItems: state.current.blotterItems,
      });
    }
    case CANCEL_AUCTION_RECEIVED: {
      const blotterItem: BlotterItem | undefined = state.current.blotterItems?.find(
        a => a.auction.auctionUuid === action.auctionUuid,
      );
      if (blotterItem === undefined) {
        return state;
      }
      blotterItem.auction.workflowStatus = 'AUCTION_CANCELLED';
      return patchObject(state, 'current', {
        ...state.current,
        blotterItems: state.current.blotterItems,
      });
    }
    case PDC_DETAILS_RECEIVED: {
      return patchObject(state, 'current', {
        ...state.current,
        pdcDetails: action.pdcDetails,
      });
    }
    case DEROGATE_RECEIVED:
    case PDC_DETAILS_FAILED: {
      return patchObject(state, 'current', {
        ...state.current,
        pdcDetails: undefined,
      });
    }
    case DEROGATION_REASONS_RECEIVED: {
      return patchObject(state, 'current', {
        ...state.current,
        pdcDerogation: action.pdcDerogation,
      });
    }
    default:
      return state;
  }
}

function updateStrategyFormResult(
  vp: VariationParameters,
  strategyFormResult?: StrategyFormResult,
): StrategyFormResult {
  return {
    ...strategyFormResult,
    values: {
      ...strategyFormResult?.values,
      ...vp,
    },
  };
}

function updateStateWhenReceivedDealers(
  state: AuctionsState,
  payload: DeepNonNullable<{
    dealers?: components['schemas']['ORfqDealer'][] | null;
  }>,
  auctionUuid: string,
): AuctionsState {
  if (state.current.blotterItems) {
    const { dealers } = payload;
    const updatedBlotterItems = state.current.blotterItems.map(blotterItem => {
      if (blotterItem.auction.auctionUuid === auctionUuid) {
        return { ...blotterItem, auction: { ...blotterItem.auction, rfqDealers: dealers } };
      } else {
        return blotterItem;
      }
    });
    return { ...state, current: { ...state.current, blotterItems: updatedBlotterItems as BlotterItem[] } };
  }
  return state;
}
