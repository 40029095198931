/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import type {
  SgwtAccountCenterHTMLElement,
  SgwtHelpCenterHTMLElement,
  SgwtMiniFooterHTMLElement,
} from '@/types/sgwt-widgets';

export interface SgwtWidgetsContextValue {
  accountCenter?: SgwtAccountCenterHTMLElement;
  helpCenter?: SgwtHelpCenterHTMLElement;
  miniFooter?: SgwtMiniFooterHTMLElement;
  setAccountCenter: (element: SgwtAccountCenterHTMLElement) => void;
  setHelpCenter: (element: SgwtHelpCenterHTMLElement) => void;
  setMiniFooter: (element: SgwtMiniFooterHTMLElement) => void;
}

export const SgwtWidgetsContext = createContext<SgwtWidgetsContextValue>({
  accountCenter: undefined,
  helpCenter: undefined,
  miniFooter: undefined,
  setAccountCenter: () => {},
  setHelpCenter: () => {},
  setMiniFooter: () => {},
});
