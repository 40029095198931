import { type FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { openAcknowledgeModal, openMissedModal } from '@/store/ui';
import { useDispatch } from 'react-redux';

export interface PendingBannerProps {
  auctionUuid?: string;
}

export const PendingBanner: FC<PendingBannerProps> = ({ auctionUuid }) => {
  const dispatch = useDispatch();

  const onOpenAcknowledgeModal = useCallback(() => {
    dispatch(openAcknowledgeModal(auctionUuid));
  }, [dispatch, auctionUuid]);

  const onOpenMissedModal = useCallback(() => {
    dispatch(openMissedModal(auctionUuid));
  }, [dispatch, auctionUuid]);

  return (
    <div className="bg-info d-flex justify-content-between">
      <div>
        <i className="icon icon-outlined icon-md m-2">info</i>
        <span>Please indicate if the dealer has confirmed or missed the trade execution</span>
      </div>
      <div className="d-flex py-2 me-2">
        <button className="btn btn-primary-alt text-info btn-md ms-auto" onClick={onOpenMissedModal}>
          <i className="icon icon-sm me-1 lh-1">close</i>
          <FormattedMessage id={`executionPendingBanner.missed`} />
        </button>
        <button className="btn btn-primary-alt text-info btn-md ms-2" onClick={onOpenAcknowledgeModal}>
          <i className="icon icon-sm me-1 lh-1">check</i>
          <FormattedMessage id={`executionPendingBanner.confirmed`} />
        </button>
      </div>
    </div>
  );
};
