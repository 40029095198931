import { copyToClipboardHandler, triggerCopyToClipboard } from '@/utils/clipboard/clipboard';
import { useCallback } from 'react';
import type { ErrorIntlKey, SuccessIntlKey } from '@/utils/clipboard/clipboard';

export function useCopyTextToClipboard(
  getText: (value?: string) => string | undefined,
  successMessage?: SuccessIntlKey,
  errorMessage?: ErrorIntlKey,
  showToast = true,
) {
  const handler = copyToClipboardHandler(successMessage, errorMessage);
  return useCallback(() => {
    triggerCopyToClipboard(handler, getText(), showToast);
  }, [handler, getText, showToast]);
}
