import { FormattedMessage } from 'react-intl';
import { Icon } from '@sgme/ui';
import { isNotDefined } from '@sgme/fp';
import type { FC } from 'react';

interface TimeZoneBannerProps {
  timeZoneId?: string;
}

export const TimeZoneBanner: FC<TimeZoneBannerProps> = ({ timeZoneId }) => {
  if (isNotDefined(timeZoneId)) {
    return null;
  }

  return (
    <div className="alert alert-discreet-info d-flex align-items-center justify-content-between ">
      <div className="align-items-center">
        <Icon icon={'info'} className="me-2 align-self-start" />
        <span>
          <FormattedMessage
            id="Please enter all time in {timeZoneId} time zone."
            values={{ timeZoneId: formatTimeZone(timeZoneId) }}
          />
        </span>
      </div>
    </div>
  );
};

export function formatTimeZone(timeZone?: string): string | undefined {
  if (!timeZone) {
    return;
  }
  const [continent, ...rest] = timeZone.split('/');
  return `${continent} / ${rest.join(' ')}`.replace('_', ' ');
}
