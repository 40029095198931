import { type FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, type ModalProps } from '@/components/shared/Modal';
import { closeMissedModal, getMissedAuctionUuid, getMissedAuctionIsLoading as getMissedIsLoading } from '@/store/ui';
import { missedAuctionRequested } from '@/store/auctions';
import { useDispatch, useSelector } from 'react-redux';

export const MissedModal: FC<ModalProps> = () => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(closeMissedModal());
  }, [dispatch]);

  const auctionUuid = useSelector(getMissedAuctionUuid());
  const isLoading = useSelector(getMissedIsLoading());

  const onConfirm = useCallback(() => {
    dispatch(missedAuctionRequested(auctionUuid));
  }, [dispatch, auctionUuid]);

  return (
    <Modal
      title="Dealer status update"
      initialDisplayed={true}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      bordered
      color="primary"
      children={
        <>
          <p>
            <FormattedMessage id="missed.confirmation" />
          </p>
        </>
      }
      loading={isLoading}
    />
  );
};
