import { Card, CardBody, CardHeader, CardTitle } from '@/components/shared/Card';
import type { FC } from 'react';

interface ErrorsBannerProps {
  errorMessages?: (string | undefined)[];
  title: string;
}

export const ErrorsBanner: FC<ErrorsBannerProps> = ({ errorMessages = [], title }) => {
  return (
    <Card className="alert alert-discreet-danger" padding={4}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardBody className="p-0">
        {errorMessages.map(message => (
          <p key={message}>{message}</p>
        ))}
      </CardBody>
    </Card>
  );
};
