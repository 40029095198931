import { addDays } from 'date-fns';
import { toEndOfDay, toStartOfDay } from '@/utils/date';
import type { Dealers, StrategyTypeEnum } from '@/models';
import type { FileCode } from '@/models/FileCode';

export interface DefaultAuctionStaticParams {
  StartTime?: string;
  EndTime?: string;
  PositionConfirmTime?: string;
  PreNotificationTime?: string;
  Strategy?: string;
  FileCode?: FileCode;
  HedgeIdDef?: number;
  Advised: boolean;
  DefaultDealers?: Dealers[];
  InstrumentType?: StrategyTypeEnum;
}

export interface ModalParams {
  override: {
    isOpened: boolean;
    auctionUuid: string | undefined;
    instrument: string | undefined;
    confirmationDisabled: boolean | undefined;
  };
  cancel: {
    isOpened: boolean;
    auctionUuid: string | undefined;
    strategy: string | undefined;
    confirmationDisabled: boolean;
  };

  pdcDetails: {
    isOpened: boolean;
    rfqId: string | undefined;
    isDerogationOpened: boolean;
    derogationParameters: DerogationParameters | undefined;
    isDerogating: boolean;
  };

  acknowledge: {
    isOpened: boolean;
    auctionUuid: string | undefined;
    isLoading: boolean;
  };

  missed: {
    isOpened: boolean;
    auctionUuid: string | undefined;
    isLoading: boolean;
  };
}

export type UiState = {
  current: { startDate: string; endDate: string };
  create: DefaultAuctionStaticParams;
  edit: DefaultAuctionStaticParams;
  modal: ModalParams;
};

export type DerogationParameters = {
  legIndex: number | undefined;
  typeId: string | undefined;
  typeLabel: string | undefined;
  isEligible: boolean | undefined;
  pdcUuid: string | undefined;
  message: string | undefined;
  derogationReason: string | undefined;
};

export const defaultAuctionStaticParameters: DefaultAuctionStaticParams = {
  StartTime: undefined,
  EndTime: undefined,
  PositionConfirmTime: undefined,
  PreNotificationTime: undefined,
  Strategy: undefined,
  FileCode: undefined,
  HedgeIdDef: undefined,
  Advised: false,
  DefaultDealers: [],
  InstrumentType: 'CALL',
};

export const modalParameters: ModalParams = {
  override: {
    isOpened: false,
    auctionUuid: undefined,
    instrument: undefined,
    confirmationDisabled: false,
  },
  cancel: {
    isOpened: false,
    auctionUuid: undefined,
    strategy: undefined,
    confirmationDisabled: false,
  },
  pdcDetails: {
    isOpened: false,
    rfqId: undefined,
    isDerogationOpened: false,
    derogationParameters: undefined,
    isDerogating: false,
  },
  acknowledge: {
    isOpened: false,
    auctionUuid: undefined,
    isLoading: false,
  },
  missed: {
    isOpened: false,
    auctionUuid: undefined,
    isLoading: false,
  },
};

export const initialUIState: UiState = {
  current: {
    startDate: toStartOfDay(new Date())?.toISOString(),
    endDate: toEndOfDay(addDays(new Date(), 7))?.toISOString(),
  },
  create: defaultAuctionStaticParameters,
  edit: defaultAuctionStaticParameters,
  modal: modalParameters,
};

export type UiPage = keyof UiState;
