import { type FC, useCallback, useRef } from 'react';
import { FormattedText } from '@/components/shared/FormattedText';
import classNames from 'classnames';
import type { ChangeEvent } from 'react';
import type { IntlKey } from '@/locales';
import type { Size } from '@sgme/ui';

export interface RadioProps {
  id?: string;
  disabled?: boolean;
  onChange?: (id: string) => void;
  className?: string;
  checked?: boolean;
  label?: IntlKey;
  groupName?: string;
  size?: Size;
}

export const RadioButton: FC<RadioProps> = ({
  id,
  disabled,
  onChange,
  className,
  checked,
  label,
  groupName,
  size = 'md',
}) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { id } = e.target;
      if (!disabled) {
        onChange?.(id);
      }
    },
    [disabled, onChange],
  );

  const input = useRef<HTMLInputElement>(null);
  return (
    <div className={classNames('form-check mb-0', `form-check-${size}`, className)}>
      <input
        type="radio"
        className="form-check-input"
        id={id}
        value=""
        onChange={handleOnChange}
        name={groupName}
        checked={checked}
        ref={input}
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {label && <FormattedText id={label} />}
      </label>
    </div>
  );
};
