import type { DateRange } from '@/models/dateRange';
import type { DerogationParameters, UiPage, UiState } from '@/store/ui/ui.model';

export type UiActions =
  | UiNewAuctionClicked
  | UiEditDetailsClicked
  | UiStaticFieldChanged
  | UiAuctionStartTimeRangeChanged
  | UiAuctionStartDateUpdated
  | OpenOverrideModal
  | CloseOverrideModal
  | OverrideRequested
  | OpenCancelModal
  | CloseCancelModal
  | OpenPdcDetailsModal
  | ClosePdcDetailsModal
  | OpenDerogation
  | CloseDerogation
  | OpenAcknowledgeModal
  | CloseAcknowledgeModal
  | OpenMissedModal
  | CloseMissedModal;

export const UI_STATIC_FIELD_CHANGED = '[UI] Static field changed' as const;
export const uiStaticFieldChanged = <T extends 'create' | 'edit', K extends UiState[T]>(
  page: T,
  payload: Partial<K>,
) => ({
  type: UI_STATIC_FIELD_CHANGED,
  page,
  payload,
});
export type UiStaticFieldChanged = ReturnType<typeof uiStaticFieldChanged>;

// TODO: Remove UI_AUCTION_START_TIME_RANGE_CHANGED in favor of CURRENT_AUCTIONS_REQUESTED
export const UI_AUCTION_START_TIME_RANGE_CHANGED = '[UI] Auction start time range changed' as const;
export const uiAuctionStartTimeRangeChanged = (page: UiPage, startTimeRange: DateRange) => ({
  type: UI_AUCTION_START_TIME_RANGE_CHANGED,
  page,
  startTimeRange,
});
export type UiAuctionStartTimeRangeChanged = ReturnType<typeof uiAuctionStartTimeRangeChanged>;

export const UI_AUCTION_START_DATE_UPDATED = '[UI] Auction start date updated' as const;
export const uiAuctionStartDateUpdated = (page: 'create' | 'edit', StartDate?: string) => ({
  type: UI_AUCTION_START_DATE_UPDATED,
  page,
  StartDate,
});
export type UiAuctionStartDateUpdated = ReturnType<typeof uiAuctionStartDateUpdated>;

export const UI_NEW_AUCTION_CLICKED = '[UI] New auction clicked' as const;
export const uiNewAuctionClicked = () => ({
  type: UI_NEW_AUCTION_CLICKED,
});
export type UiNewAuctionClicked = ReturnType<typeof uiNewAuctionClicked>;

export const UI_EDIT_DETAILS_CLICKED = '[UI] Edit details clicked' as const;
export const uiEditDetailsClicked = () => ({
  type: UI_EDIT_DETAILS_CLICKED,
});
export type UiEditDetailsClicked = ReturnType<typeof uiEditDetailsClicked>;

export const OPEN_OVERRIDE_MODAL = '[UI] Open override modal' as const;
export const openOverrideModal = (auctionUuid: string, instrument: string) => ({
  type: OPEN_OVERRIDE_MODAL,
  auctionUuid,
  instrument,
});
export type OpenOverrideModal = ReturnType<typeof openOverrideModal>;

export const CLOSE_OVERRIDE_MODAL = '[UI] Close override modal' as const;
export const closeOverrideModal = () => ({
  type: CLOSE_OVERRIDE_MODAL,
});
export type CloseOverrideModal = ReturnType<typeof closeOverrideModal>;

export const OVERRIDE_REQUESTED = '[UI] Override confirmation requested' as const;
export const overrideRequested = () => ({
  type: OVERRIDE_REQUESTED,
});
export type OverrideRequested = ReturnType<typeof overrideRequested>;

export const OPEN_CANCEL_MODAL = '[UI] Open cancel modal' as const;
export const openCancelModal = (auctionUuid: string, strategy?: string) => ({
  type: OPEN_CANCEL_MODAL,
  auctionUuid,
  strategy,
});
export type OpenCancelModal = ReturnType<typeof openCancelModal>;

export const CLOSE_CANCEL_MODAL = '[UI] Close cancel modal' as const;
export const closeCancelModal = () => ({
  type: CLOSE_CANCEL_MODAL,
});
export type CloseCancelModal = ReturnType<typeof closeCancelModal>;

export const OPEN_PDC_DETAILS_MODAL = '[UI] Open pdc details modal' as const;
export const openPdcDetailsModal = (rfqId: string | undefined) => ({
  type: OPEN_PDC_DETAILS_MODAL,
  rfqId: rfqId,
});
export type OpenPdcDetailsModal = ReturnType<typeof openPdcDetailsModal>;

export const CLOSE_PDC_DETAILS_MODAL = '[UI] Close pdc details modal' as const;
export const closePdcDetailsModal = () => ({
  type: CLOSE_PDC_DETAILS_MODAL,
});
export type ClosePdcDetailsModal = ReturnType<typeof closePdcDetailsModal>;

export const OPEN_DEROGATION = '[UI] Open derogation' as const;
export const openDerogation = (derogationParameters: DerogationParameters) => ({
  type: OPEN_DEROGATION,
  derogationParameters: derogationParameters,
});
export type OpenDerogation = ReturnType<typeof openDerogation>;

export const CLOSE_DEROGATION = '[UI] Close derogation' as const;
export const closeDerogation = () => ({
  type: CLOSE_DEROGATION,
});
export type CloseDerogation = ReturnType<typeof closeDerogation>;

export const OPEN_ACKNOWLEDGE_MODAL = '[UI] Open acknowledge modal' as const;
export const openAcknowledgeModal = (auctionUuid: string | undefined) => ({
  type: OPEN_ACKNOWLEDGE_MODAL,
  auctionUuid: auctionUuid,
});
export type OpenAcknowledgeModal = ReturnType<typeof openAcknowledgeModal>;

export const CLOSE_ACKNOWLEDGE_MODAL = '[UI] Close acknowledge modal' as const;
export const closeAcknowledgeModal = () => ({
  type: CLOSE_ACKNOWLEDGE_MODAL,
});
export type CloseAcknowledgeModal = ReturnType<typeof closeAcknowledgeModal>;

export const OPEN_MISSED_MODAL = '[UI] Open missed modal' as const;
export const openMissedModal = (auctionUuid: string | undefined) => ({
  type: OPEN_MISSED_MODAL,
  auctionUuid: auctionUuid,
});
export type OpenMissedModal = ReturnType<typeof openMissedModal>;

export const CLOSE_MISSED_MODAL = '[UI] Close missed modal' as const;
export const closeMissedModal = () => ({
  type: CLOSE_MISSED_MODAL,
});
export type CloseMissedModal = ReturnType<typeof closeMissedModal>;
