import { Card, CardBody, CardHeader, CardTitle } from '@/components/shared/Card';
import { FormattedDate } from '@/components/shared/FormattedDate';
import { FormattedNumber } from '@/components/shared/FormattedNumber';
import { FormattedStrikes } from '@/components/shared/FormattedStrikes';
import { FormattedText } from '@/components/shared/FormattedText';
import { type IntlKey } from '@/locales';
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/shared/Table';
import { useToggleFeature } from '@/hooks/useToggleFeature/useToggleFeature';
import type { FC } from 'react';
import type { Instrument } from '@/models';

export interface InstrumentTableProps {
  className?: string;
  instrument?: Instrument;
  showExecutedBadge?: boolean;
}

const headers: { intlKey: IntlKey; alignRight: boolean }[] = [
  { intlKey: 'Instrument type', alignRight: false },
  { intlKey: 'SG way', alignRight: false },
  { intlKey: 'Underlying', alignRight: false },
  { intlKey: 'Maturity', alignRight: false },
  { intlKey: 'Strike(s)', alignRight: true },
  { intlKey: 'Lot size', alignRight: true },
  { intlKey: 'Size (of lots)', alignRight: true },
  { intlKey: 'Quantity', alignRight: true },
  { intlKey: 'Delivery type', alignRight: false },
  { intlKey: 'Option style', alignRight: false },
  { intlKey: 'Nego type', alignRight: false },
  { intlKey: 'Determination method', alignRight: false },
];

export const InstrumentTable: FC<InstrumentTableProps> = ({ className, instrument, showExecutedBadge }) => {
  const masterLeg = instrument?.instrumentLegs?.find(leg => leg.master);
  const strikes = instrument?.instrumentLegs?.map(leg => leg.strike);
  const computedClientWay = instrument?.computedClientWay;
  const sgWay = computedClientWay === 'BUY' ? 'SELL' : computedClientWay === 'SELL' ? 'BUY' : computedClientWay;
  const isDisplayExecutionDetailsEnabled = useToggleFeature('DisplayExecutionDetails');
  return (
    <Card padding={3} className={className}>
      <CardHeader>
        <CardTitle size={6}>
          <FormattedText id={`instrumentType.${instrument?.instrumentType}`} />
          <span className="me-1" />
          <FormattedText text={masterLeg?.underlying?.bloombergCode} />
          {showExecutedBadge && isDisplayExecutionDetailsEnabled && (
            <span className={`ms-2 badge badge-discreet-success rounded-pill`}>
              <FormattedText text={'Executed'} />
            </span>
          )}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Table className="w-100 table-sm m-0">
          <Thead>
            <Tr>
              {headers.map(header => (
                <FormattedText
                  as={Th}
                  key={header.intlKey}
                  id={header.intlKey}
                  className="text-secondary"
                  style={{ textAlign: header.alignRight ? 'right' : undefined }}
                />
              ))}
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <FormattedText as={Td} id={`instrumentType.${instrument?.instrumentType}`} />
              <FormattedText as={Td} id={`sgWay.${sgWay}`} />
              <FormattedText as={Td} text={masterLeg?.underlying?.bloombergCode} />
              <FormattedDate as={Td} value={masterLeg?.maturity?.date} />
              <FormattedStrikes as={Td} strikes={strikes} align="right" />
              <FormattedNumber as={Td} value={masterLeg?.negotiatedSize?.lotSize} align="right" />
              <FormattedNumber as={Td} value={masterLeg?.negotiatedSize?.numberOfLots} align="right" />
              <FormattedNumber
                as={Td}
                className="fw-semibold"
                value={masterLeg?.negotiatedSize?.quantity}
                align="right"
              />
              <FormattedText as={Td} id={`deliveryStyle.${masterLeg?.deliveryStyle}`} />
              <FormattedText as={Td} id={`instrumentStyle.${instrument?.styles}`} />
              <FormattedText as={Td} id={`negoType.${instrument?.negoType}`} />
              <FormattedText as={Td} id={`determinationMethod.${instrument?.determinationMethod}`} />
            </Tr>
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
